import { csCZ } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { isEmpty, omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { setXGridConfig } from '../store/ui/actions';
import { getXgridConfig } from '../store/ui/selectors';
import arrToObjIdAsKey from '../utils/arrToObjIdAsKey';
import debounce from '../utils/debounce';
import { jsKeywordsToWords } from '../utils/prepareXGridData';
import XGridCustomToolbar from './XGridCustomToolbar';

const sortColumns = (_columns, config) => {
	let columns = [..._columns];

	if (config.invisibleComulmns && !isEmpty(config.invisibleComulmns)) {
		columns = columns.map((column) => ({ ...column, hide: config.invisibleComulmns.includes(column.field) }));
	}

	if (!config.columnsOrder || isEmpty(config.columnsOrder)) return columns;
	const columnsByField = arrToObjIdAsKey(columns, 'field');
	return config.columnsOrder.filter((field) => field !== '__check__').map((field) => columnsByField[field]);
};

const DataGrid = (props) => {
	const { gridId, columns, rows } = props;
	const dispatch = useDispatch();
	const xgridConfig = useSelector(getXgridConfig());
	const config = xgridConfig[gridId];

	const dataGridConfig = {
		density: 'compact',
		components: { Toolbar: () => <XGridCustomToolbar fileName={gridId} /> },
		localeText: csCZ.components.MuiDataGrid.defaultProps.localeText,
		columns: sortColumns(columns, config),
		rows: jsKeywordsToWords(rows),
		pagination: true,
		ignoreDiacritics: true,
		initialState: {
			filter: { filterModel: config.filterModel },
			sorting: { sortModel: config.sortModel },
			columns: { columnVisibilityModel: config.columnVisibilityModel },
			pinnedColumns: config.pinnedColumns,
		},

		// remember filtering
		onFilterModelChange: (e) => dispatch(setXGridConfig({ ...config, filterModel: e }, gridId)),

		// remember sorting
		onSortModelChange: (e) => dispatch(setXGridConfig({ ...config, sortModel: e }, gridId)),

		// remember columns visibility
		onColumnVisibilityModelChange: (e) => dispatch(setXGridConfig({ ...config, columnVisibilityModel: e }, gridId)),

		// remember columns order
		onColumnOrderChange: (e, _, { api }) => {
			debounce(() => {
				dispatch(setXGridConfig({ ...config, columnsOrder: api.getAllColumns().map((s) => s.field) }, gridId));
			});
		},

		// remember pinned columns
		onPinnedColumnsChange: (e) => dispatch(setXGridConfig({ ...config, pinnedColumns: e }, gridId)),
	};

	return <DataGridPro {...dataGridConfig} {...omit(['columns', 'gridId', 'rows'], props)} />;
};

export default DataGrid;
