import {
	ArchiveOutlined,
	Delete,
	DeleteForever,
	FileCopy,
	FileOpen,
	PowerOff,
	StarBorderOutlined,
	Stars,
	UnarchiveOutlined,
} from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { ENTITIES, ROUTES, USER, YES_NO_VALUE_OPTIONS } from '../../consts';
import messages from '../../intl/messages';
import { getUserRole } from '../../store/auth/selectors';
import {
	hideModal,
	showConfirmModal,
	showFinishDefinitionExamsModal,
	showRemoveDefinitionModal,
} from '../../store/modal/actions';
import {
	deleteArchivedExams,
	editDefinition,
	fetchDefaultDefinition,
	fetchDefinition,
	fetchDefinitions,
} from '../../store/model/actions';
import { getDefinitionsList, getTermsForExamFilter } from '../../store/model/selectors';
import formatDate from '../../utils/formatDate';
import { numericOperators, selectOperators, stringOperatorsExtended } from '../../utils/prepareXGridData';
import redirectInsteadMenu from '../../utils/redirectInsteadMenu';
import DataGrid from '../DataGrid';
import { useStyles } from '../Exams/ExamsList';
import UniButtton from '../UniButtton';
import XGridCustomToolbar from '../XGridCustomToolbar';

const Definitions = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const definitions = useSelector(getDefinitionsList);
	const terms = useSelector(getTermsForExamFilter());
	const dispatch = useDispatch();
	const userRole = useSelector(getUserRole());
	const isSuperadmin = userRole === USER.ROLES.SUPERADMIN;
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchDefinitions());
	}, [dispatch]);

	const createNewFromDefault = async () => {
		const { id } = await dispatch(fetchDefaultDefinition());
		if (id) navigate(`${ROUTES.SETTINGS.PATH}/${id}`);
	};

	const copyDefinition = async (originalDefId) => {
		const { id } = await dispatch(fetchDefinition(originalDefId));
		if (id) navigate(`${ROUTES.SETTINGS.PATH}/${id}`);
	};

	const columns = [
		{
			field: 'detail',
			headerName: '',
			width: 55,
			resizable: false,
			filterable: false,
			sortable: false,
			pinnable: false,
			disableExport: true,
			renderCell: (params) => (
				<Link
					to={`${ROUTES.SETTINGS.PATH}/${params.row.id}`}
					onContextMenu={(e) => redirectInsteadMenu(e, `${ROUTES.SETTINGS.PATH}/${params.row.id}`)}
				>
					<UniButtton type="icon" icon={<FileOpen />} tooltip={<FormattedMessage {...messages.detail} />} />
				</Link>
			),
		},
		{
			field: 'id',
			headerName: intl.formatMessage(messages.definitionId),
			width: 320,
			cellClassName: 'cursorAuto',
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'name',
			headerName: intl.formatMessage(messages.definition),
			width: 220,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'term',
			headerName: intl.formatMessage(messages.term),
			width: 180,
			type: 'singleSelect',
			filterOperators: selectOperators(terms, intl),
		},
		{
			field: 'participantsCount',
			headerName: intl.formatMessage(messages.expectedExams),
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			width: 150,
			filterOperators: numericOperators(intl),
		},
		{
			field: 'runningExamsCount',
			headerName: intl.formatMessage(messages.running),
			width: 150,
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'finishedExamsCount',
			headerName: intl.formatMessage(messages.finished),
			width: 150,
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'isOnboarding',
			headerName: intl.formatMessage(messages.isOnboarding),
			width: 100,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'priority',
			headerName: intl.formatMessage(messages.priority),
			width: 180,
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'isArchived',
			headerName: intl.formatMessage(messages.isArchived),
			width: 180,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'logViolatedOnly',
			headerName: intl.formatMessage(messages.logViolatedOnly),
			width: 290,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'startsAt',
			headerName: intl.formatMessage(messages.startsAt),
			width: 180,
			renderCell: (params) => <div>{formatDate(params.row.startsAt).slice(0, -3)}</div>,
			type: 'dateTime',
			valueGetter: (params) => new Date(params.row.startsAt),
		},
		{
			field: 'endsAt',
			headerName: intl.formatMessage(messages.endsAt),
			width: 180,
			renderCell: (params) => <div>{formatDate(params.row.endsAt).slice(0, -3)}</div>,
			type: 'dateTime',
			valueGetter: (params) => new Date(params.row.endsAt),
		},
		{
			field: 'archiveAfterDays',
			headerName: intl.formatMessage(messages.archive),
			width: 180,
			renderCell: (params) =>
				params.row.archiveAfterDays ? (
					<div>
						{formatDate(new Date(+new Date(params.row.endsAt) + params.row.archiveAfterDays * 24 * 60 * 60 * 1000)).slice(0, -3)}
					</div>
				) : null,
			type: 'dateTime',
			valueGetter: (params) =>
				params.row.archiveAfterDays
					? new Date(+new Date(params.row.endsAt) + params.row.archiveAfterDays * 24 * 60 * 60 * 1000)
					: null,
		},
		{
			field: 'deleteAfterDays',
			headerName: intl.formatMessage(messages.remove),
			width: 180,
			renderCell: (params) =>
				params.row.deleteAfterDays ? (
					<div>
						{formatDate(new Date(+new Date(params.row.endsAt) + params.row.deleteAfterDays * 24 * 60 * 60 * 1000)).slice(0, -3)}
					</div>
				) : null,
			type: 'dateTime',
			valueGetter: (params) =>
				params.row.deleteAfterDays
					? new Date(+new Date(params.row.endsAt) + params.row.deleteAfterDays * 24 * 60 * 60 * 1000)
					: null,
		},
		{
			field: 'action',
			headerName: intl.formatMessage(messages.actions),
			disableColumnMenu: true,
			filterable: false,
			width: 400,
			type: 'string',
			renderCell: ({ row }) => {
				const isArchived = row.isArchived === intl.formatMessage(messages.yes);
				const isDefault = row.isDefault === intl.formatMessage(messages.yes);
				return (
					<div style={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
						<UniButtton
							tooltip={<FormattedMessage {...messages[isDefault ? 'default' : 'setDefault']} />}
							disabled={isDefault}
							onClick={(e) =>
								dispatch(
									editDefinition(
										{
											...definitions[definitions.findIndex((def) => def.id === row.id)],
											isDefault: true,
										},
										intl
									)
								)
							}
							icon={isDefault ? <Stars fontSize="small" /> : <StarBorderOutlined fontSize="small" />}
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages.copyDefinition} />}
							onClick={() => copyDefinition(row.id)}
							icon={<FileCopy fontSize="small" />}
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages[isArchived ? 'unarchiveDefinition' : 'archiveDefinition']} />}
							onClick={() =>
								dispatch(
									showConfirmModal({
										handler: () => {
											dispatch(
												editDefinition(
													{
														...definitions[definitions.findIndex((def) => def.id === row.id)],
														isArchived: !isArchived,
													},
													intl
												)
											);
											dispatch(hideModal());
										},
										text: isArchived ? (
											<FormattedMessage {...messages.unarchiveDefinitionPrompt} />
										) : (
											<FormattedMessage {...messages.archiveDefinitionPrompt} />
										),
										button: isArchived ? (
											<FormattedMessage {...messages.unarchiveDefinition} />
										) : (
											<FormattedMessage {...messages.archiveDefinition} />
										),
									})
								)
							}
							icon={isArchived ? <UnarchiveOutlined fontSize="small" /> : <ArchiveOutlined fontSize="small" />}
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages.removeDefinition} />}
							disabled={isDefault}
							onClick={() => dispatch(showRemoveDefinitionModal(row.id))}
							icon={<Delete />}
						/>
						{isSuperadmin && (
							<UniButtton
								tooltip={<FormattedMessage {...messages.finishExams} />}
								onClick={() => dispatch(showFinishDefinitionExamsModal(row.id))}
								icon={<PowerOff />}
							/>
						)}
						{isSuperadmin && (
							<UniButtton
								tooltip={<FormattedMessage {...messages.deleteArchivedExams} />}
								disabled={!isArchived}
								onClick={() =>
									dispatch(
										showConfirmModal({
											handler: () => {
												dispatch(deleteArchivedExams(row.id, intl));
												dispatch(hideModal());
											},
											text: <FormattedMessage {...messages.deleteArchivedExamsPrompt} />,
											title: <FormattedMessage {...messages.deleteArchivedExams} />,
											button: <FormattedMessage {...messages.deleteArchivedExams} />,
										})
									)
								}
								icon={<DeleteForever />}
							/>
						)}
					</div>
				);
			},
		},
	];

	const dataGridConfig = {
		gridId: ENTITIES.DEFINITIONS_LIST,
		components: {
			Toolbar: () => (
				<XGridCustomToolbar disableExport>
					<Button variant="contained" color="primary" className="rightFloatAction" onClick={createNewFromDefault}>
						<FormattedMessage {...messages.createNewFromDefault} />
					</Button>
				</XGridCustomToolbar>
			),
		},
		rows: definitions,
		columns,
	};

	return (
		<Paper className={classes.bounding} style={{ height: '800px' }}>
			<div style={{ height: '800px' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default Definitions;
