import { isEmpty } from 'ramda';
import keyMirror from '../../utils/keyMirror';
import { makeActionCreator } from '../../utils/redux';

export const UI_ACTION_TYPES = keyMirror({
	SET_CURRENT_LANG_CODE: null,
	SET_CURRENT_LIST_TAB: null,
	SET_CURRENT_SECTION_TAB: null,
	SET_EXAMS_LIST_CONFIG: null,
	SET_PAGE_TITLE: null,
	SET_VIDEO_SEEK: null,
	SET_VIDEO_STATE: null,
	SET_XGRID_CONFIG: null,
	SET_IS_PIP: null,
	SET_TYPE_FILTER: null,
	SET_VIDEO_PLAYBACK_RATE: null,
});

export const setXGridConfig = (config, configEntity) => async (dispatch) => {
	const isFilterValEmpty =
		!isEmpty(config.filterModel.items) &&
		!config.filterModel.items?.[0]?.value &&
		config.filterModel.items?.length === 1;

	dispatch({
		type: UI_ACTION_TYPES.SET_XGRID_CONFIG,
		payload: {
			config: isFilterValEmpty
				? {
						...config,
						filterModel: { ...config.filterModel, items: [...config.filterModel.items] },
				  }
				: config,
			configEntity,
		},
	});
};

export const setCurrentListTab = makeActionCreator(UI_ACTION_TYPES.SET_CURRENT_LIST_TAB, 'tabId');
export const setCurrentLangCode = makeActionCreator(UI_ACTION_TYPES.SET_CURRENT_LANG_CODE, 'langCode');
export const setVideoSeek = makeActionCreator(UI_ACTION_TYPES.SET_VIDEO_SEEK, 'seek');
export const setVideoState = makeActionCreator(UI_ACTION_TYPES.SET_VIDEO_STATE, 'state');
export const setPageTitle = makeActionCreator(UI_ACTION_TYPES.SET_PAGE_TITLE, 'pageTitle');
export const setIsPip = makeActionCreator(UI_ACTION_TYPES.SET_IS_PIP, 'isPip');
export const setTypeFilter = makeActionCreator(UI_ACTION_TYPES.SET_TYPE_FILTER, 'typeFilter');
export const setVideoPlaybackRate = makeActionCreator(UI_ACTION_TYPES.SET_VIDEO_PLAYBACK_RATE, 'playbackRate');
