import axiosClient from 'axios';
import { omit, pick } from 'ramda';
import { API_URL, ENTITIES, LS_USER } from './consts';

// TODO: cleanup - make a func for the redundant / repeated code

const axios = axiosClient.create({
	withCredentials: true,
});

const refreshToken = ({ reject }) =>
	new Promise(() =>
		axios.post(API_URL[ENTITIES.REFRESH_TOKEN]).then(
			async () => reject('REPEAT_ORIGINAL_CALL'),
			(e) => {
				if (e.response.status === 401) {
					localStorage.removeItem(LS_USER);
					reject(e);
				}
			}
		)
	);

const catchFail = async ({ callback, e, reject }) => {
	if (e.response.status === 401 && !window.location.href.includes('login')) {
		await refreshToken({ reject });
		return;
	}

	if (e.response.status === 403 && callback) callback();

	return reject(e);
};

export const create2FATokenRequest = async (userId) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL.CREATE_2FA_TOKEN + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getChecklists = async (definitionId, lang) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.CHECKLISTS](definitionId) + '?lang=' + lang).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putCheck = (check, lang, id) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.CHECKLISTS](id) + '?lang=' + lang, check).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getCheckpoints = (definitionId, lang) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.CHECKPOINTS](definitionId) + '?lang=' + lang).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putCheckpoint = (checkpoint, lang) =>
	new Promise((resolve, reject) =>
		axios
			.put(
				API_URL[ENTITIES.CHECKPOINTS](checkpoint.id) + '?lang=' + lang,
				pick(
					[
						'confidenceThreshold',
						'confidenceThresholdLow',
						'displayed',
						'enabled',
						'frameOccurence',
						'violationMessage',
					],
					checkpoint
				)
			)
			.then(
				(response) => resolve(response.data),
				(e) => catchFail({ e, reject })
			)
	);

export const deleteProcess = (id) =>
	new Promise((resolve, reject) =>
		axios.delete(API_URL[ENTITIES.PROCESS](id)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const addProcess = (process, definitionId) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.PROCESS](definitionId), process).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const editProcess = (processId, process) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.PROCESS](processId), process).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const postDefaultDefinition = () =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.DEFINITIONS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const postDefinition = (definitionId) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.DEFINITIONS] + '/' + definitionId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putDefinition = (definition, lang) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.DEFINITIONS] + '/' + definition.id + '?lang=' + lang, omit(['id'], definition)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const deleteDefinition = (definitionId) =>
	new Promise((resolve, reject) =>
		axios.delete(API_URL[ENTITIES.DEFINITIONS] + '/' + definitionId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const finishDefinition = (definitionId) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.FINISH_DEFINITION] + '/' + definitionId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const deleteArchivedExams = (definitionId) =>
	new Promise((resolve, reject) =>
		axios.delete(API_URL[ENTITIES.EXAMS](definitionId)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getDefinitions = (lang) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.DEFINITIONS] + '?lang=' + lang).then(
			(response) => {
				resolve(response.data);
			},
			(e) => catchFail({ e, reject })
		);
	});

export const getDashboardExams = ({ userId }) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.DASHBOARD] + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const processExam = (examId) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.PROCESS_EXAM] + '/' + examId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const processExamsList = (examsIds) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.PROCESS_EXAM], { examsIds }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getEvaluations = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.EVALUATIONS]).then(
			(response) => {
				resolve(response.data);
			},
			(e) => catchFail({ e, reject })
		);
	});

export const getExam = (callback, definitionId, examId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.EXAMS](definitionId) + '/' + examId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ callback, e, reject })
		);
	});

export const getExams = (definitionId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.EXAMS](definitionId)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getExamsForReviewCount = (userId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.EXAMS_FOR_REVIEW_COUNT](userId)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getExamsForCommissionerReviewCount = (userId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.EXAMS_FOR_COMMISSIONER_REVIEW_COUNT](userId)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getFilteredExams = (filterModel, page = 1, limit = 100, sortBy = '', sortDirection = 'asc') =>
	new Promise((resolve, reject) => {
		axios({
			method: 'post',
			url: API_URL[ENTITIES.EXAMS_FILTER],
			params: {
				page,
				limit,
				sortBy,
				sortDirection,
			},
			data: filterModel,
		}).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const deleteExams = (examIds) =>
	new Promise((resolve, reject) =>
		axios.delete(API_URL[ENTITIES.EXAMS](''), { data: { examIds } }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getExamByExternalId = (externalId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.EXAMS_EXTERNAL](externalId)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getWebcamUrl = (examId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.WEBCAM](examId)).then(
			(response) => resolve(response.data.url),
			(e) => catchFail({ e, reject })
		)
	);

export const getViolations = (examId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.VIOLATIONS] + '/' + examId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const deleteViolationRequest = (id) =>
	new Promise((resolve, reject) => {
		axios.delete(API_URL[ENTITIES.VIOLATIONS] + '/' + id).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScreenUrl = (examId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.SCREEN](examId)).then(
			(response) => resolve(response.data.url),
			(e) => catchFail({ e, reject })
		)
	);

export const loginRequest = ({ email, password }) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.LOGIN], { email, password }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const login2FARequest = ({ userId, code, tokenId }) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL.VERIFY_2FA_TOKEN + '/' + userId, { code, tokenId }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getSignedUp = ({ email, has2fa, maxPostponedExams, name, phoneNumber, role, shouldSendEmail, surname }) =>
	new Promise((resolve, reject) =>
		axios
			.post(API_URL[ENTITIES.SIGNUP], {
				email,
				has2fa,
				maxPostponedExams: maxPostponedExams || 0,
				name,
				phoneNumber,
				role,
				shouldSendEmail,
				surname,
			})
			.then(
				(response) => resolve(response.data),
				(e) => catchFail({ e, reject })
			)
	);

export const getUserByTokenRequest = ({ token }) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.USER_BY_TOKEN] + '/' + token).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const forgetPasswordRequest = ({ email }) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL.FORGET_PASSWORD + '/' + email).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const changePasswordRequest = ({ password, confirmPassword, userId }) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.PASSWORD] + '/' + userId, { password, confirmPassword }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getUsers = () =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.USERS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getIdUrl = (examId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.ID_URL](examId)).then(
			(response) => resolve({ idUrl: response.data.url, examId }),
			(e) => catchFail({ e, reject })
		)
	);

export const getFaceUrl = (examId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.FACE_URL](examId)).then(
			(response) => resolve({ faceUrl: response.data.url, examId }),
			(e) => catchFail({ e, reject })
		)
	);

export const getRoomVideoUrl = (examId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.VIDEO_ROOM](examId)).then(
			(response) => resolve(response.data.url),
			(e) => catchFail({ e, reject })
		)
	);

export const putViolationReview = (violation) =>
	new Promise((resolve, reject) =>
		axios
			.put(
				API_URL[ENTITIES.VIOLATION_REVIEW](violation.userId, violation.violationId),
				omit(['userId', 'violationId'], violation)
			)
			.then(
				(response) => resolve(response.data),
				(e) => catchFail({ e, reject })
			)
	);

export const putViolationsReview = ({ evaluationId, violationIds, userId }) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.VIOLATION_REVIEWS](userId), { evaluationId, violationIds }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const postNewViolation = (violation) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.VIOLATIONS], violation).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const activateUser = (userId) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.ACTIVATE_USER] + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const deactivateUser = (userId) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.DEACTIVATE_USER] + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const deleteUser = (userId) =>
	new Promise((resolve, reject) =>
		axios.delete(API_URL[ENTITIES.USERS] + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getQueueReview = (userId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.REVIEWS] + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getSpecializedQueueReview = (userId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.REVIEWS] + '/commissioner/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const postReview = ({ examId, userRole, payload }) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.REVIEWS] + '/' + userRole + '/' + examId, payload).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getNotes = (examId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.NOTES] + '/' + examId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getAllNotes = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.NOTES]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const postNote = ({ examId, userId, type, text }) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL[ENTITIES.NOTES], { examId, userId, type, text }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putNote = ({ text, type, noteId }) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.NOTES] + '/' + noteId, { type, text }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const deleteNote = ({ noteId }) =>
	new Promise((resolve, reject) =>
		axios.delete(API_URL[ENTITIES.NOTES] + '/' + noteId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const renewPasswordRequest = (userId) =>
	new Promise((resolve, reject) =>
		axios.post(API_URL.RENEW_PASSWORD + '/' + userId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const editUserRequest = ({ name, surname, userId, role, skill, has2fa, phoneNumber, maxPostponedExams }) =>
	new Promise((resolve, reject) =>
		axios
			.put(API_URL[ENTITIES.USERS] + '/' + userId, {
				name,
				surname,
				role,
				skill,
				has2fa,
				phoneNumber,
				maxPostponedExams: maxPostponedExams || 0,
			})
			.then(
				(response) => resolve(response.data),
				(e) => catchFail({ e, reject })
			)
	);

export const putFinishExam = ({ examId }) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.FINISH_EXAM](examId)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putPostponeReview = ({ examId, userId }) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.POSTPONE_REVIEW], { examId, userId }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putResetPostponedReviews = (examIds) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.RESET_POSTPONED_REVIEWS], { examIds }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putResetAssignedReviews = (examIds) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.RESET_ASSIGNED_REVIEWS], { examIds }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putInstruction = (instruction) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.INSTRUCTIONS] + '/' + instruction.id, pick(['text'], instruction)).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const postFile = (entityId, file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append('file', file);

		axios.post(API_URL[ENTITIES.UPLOAD] + '/' + entityId, formData).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getInstructions = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.INSTRUCTIONS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingDeployments = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_DEPLOYMENTS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingNodes = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_NODES]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingDb = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_DB]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingCluster = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_CLUSTER]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingHistory = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_HISTORY]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingUsersCount = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_USERS_COUNT]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getSwitchValues = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_SWITCH]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getAutoscalerStatus = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_AUTOSCALER_STATUS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingJobsCount = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_JOBS_COUNT]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getScalingStatus = (jobId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.SCALING_STATUS] + '/' + jobId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getRunningExamsCount = (jobId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.RUNNING_EXAMS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const getRunningJobsCount = (jobId) =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.RUNNING_JOBS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const putScaling = (usersCount, shouldScaleModels, shouldScaleBackend, shouldScaleDb) =>
	new Promise((resolve, reject) =>
		axios
			.put(API_URL[ENTITIES.SCALING_SCALE], {
				numberOfUsers: usersCount,
				shouldScaleModels,
				shouldScaleBackend,
				shouldScaleDb,
			})
			.then(
				(response) => resolve(response.data),
				(e) => catchFail({ e, reject })
			)
	);

export const putStartAutoscaler = () =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.START_AUTOSCALER]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putStopAutoscaler = () =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.STOP_AUTOSCALER]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putScalingPostprocessing = (jobsCount) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.SCALING_SCALE_POSTPROCESSING] + '/' + jobsCount).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const startAksCluster = (usersCount, shouldScaleModels) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.SCALING_CLUSTER_START]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);
export const stopAksCluster = (usersCount, shouldScaleModels) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.SCALING_CLUSTER_STOP]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getStats = () =>
	new Promise((resolve, reject) => {
		axios.get(API_URL[ENTITIES.STATS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		);
	});

export const putAssignReviews = (examIds, userId) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.ASSIGN_REVIEWS], { examIds, userId }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putAssignReviewToCommisioner = (examId, userId) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.ASSIGN_REVIEWS] + '/' + userId, { examId }).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const putUnassignReview = (examId) =>
	new Promise((resolve, reject) =>
		axios.put(API_URL[ENTITIES.UNASSIGN_REVIEW] + '/' + examId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getEvaluationDetails = () =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.EVALUATION_DETAILS]).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);

export const getPreviousExams = (examId) =>
	new Promise((resolve, reject) =>
		axios.get(API_URL[ENTITIES.EXAMS_PREVIOUS] + '/' + examId).then(
			(response) => resolve(response.data),
			(e) => catchFail({ e, reject })
		)
	);
