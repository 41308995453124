/* eslint-disable no-unused-vars */
import {
	Delete,
	CheckCircle,
	Error,
	Help,
	HourglassEmpty,
	PersonAdd,
	Refresh,
	ReplyAll,
	RotateLeft,
	ThumbDown,
	ThumbUp,
	FileOpen,
	SyncProblem,
} from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useGridApiRef, gridExpandedSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import { isEmpty } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
	COMMISSIONER_REVIEW,
	ENTITIES,
	EXAM,
	JOB_STATUS,
	REVIEWER_REVIEW,
	ROUTES,
	USER,
	YES_NO_VALUE_OPTIONS,
} from '../../consts';
import messages from '../../intl/messages';
import { fetchUsers } from '../../store/auth/actions';
import { getUserRole, getUsers } from '../../store/auth/selectors';
import { hideModal, showAssignReviewModal, showConfirmModal, showRemoveExamsModal } from '../../store/modal/actions';
import {
	MODEL_ACTION_TYPES,
	assignReviews,
	fetchExams,
	resetAssignedReviews,
	resetPostponedReviews,
	restartProcessing,
} from '../../store/model/actions';
import { getCurrentDefinition, getCurrentExams, isPending } from '../../store/model/selectors';
import { setXGridConfig } from '../../store/ui/actions';
import { getXgridConfig } from '../../store/ui/selectors';
import _debouce from '../../utils/debounce';
import formatDate, { msToTime, toDateCzLocalized } from '../../utils/formatDate';
import {
	numericOperators,
	prepareExams,
	selectOperators,
	selectOperatorsWEmpty,
	stringOperatorsExtended,
	timeLengthOperators,
} from '../../utils/prepareXGridData';
import redirectInsteadMenu from '../../utils/redirectInsteadMenu';
import DataGrid from '../DataGrid';
import Breadcrumbs from './../Breadcrumbs';
import UniButtton from './../UniButtton';
import XGridCustomToolbar from './../XGridCustomToolbar';

export const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .hidden': {
			display: 'none !important',
			width: 0,
			position: 'absolute !important',
			padding: 0,
			margin: 0,
		},
		'& h2': {
			margin: 0,
			fontWeight: 200,
			color: theme.palette.primary.dark,
			fontSize: '1.3rem',
			textAlign: 'center',
		},
		'&.MuiPaper-root': {
			fontSize: '0.9rem',
			padding: '0.5rem',
			height: 'calc(100vh - 160px)',
			minHeight: '400px',
			position: 'relative',
			'& h2': {
				margin: '10px 0',
				padding: '0 10px',
				textAlign: 'left',
				display: 'flex',
				justifyContent: 'space-between',
				fontSize: '1.2rem',
				marginBottom: '1rem',
				color: 'black',
				fontWeight: 700,
				'& button': {
					padding: 0,
				},
			},
		},
		'& .MuiPaper-root.gridBounding': {
			height: 'calc(50vh - 125px)',
		},
		'& .MuiDataGrid-root': {
			width: '100%',
			'& .MuiDataGrid-columnHeaderDraggableContainer': {
				flexDirection: 'row',
			},
			'& .MuiDataGrid-columnHeaderTitleContainer': {
				flexDirection: 'initial',
			},
			'& .MuiDataGrid-toolbar': {
				padding: '7px 12px',
			},
			'&.withHeaderFilters.MuiDataGrid-root--densityCompact': {
				'& .MuiDataGrid-columnHeaders': {
					minHeight: '90px !important',
					maxHeight: '90px !important',
				},
			},
			'&.withHeaderFilters.MuiDataGrid-root--densityComfortable': {
				'& .MuiDataGrid-columnHeaders': {
					minHeight: '125px !important',
					maxHeight: '125px !important',
				},
			},
			'& .MuiDataGrid-columnHeaders': {
				background: theme.palette.primary.main,
				color: theme.palette.secondary.main,
				'& .MuiSvgIcon-root': {
					fill: theme.palette.primary.dark,
				},
			},
			'& .MuiDataGrid-headerFilterRow': {
				background: 'white',
				'& .MuiDataGrid-columnHeader': {
					height: '50px !important',
					'& .MuiFormControl-root': {
						width: '100%',
					},
				},
			},
			'& .MuiDataGrid-pinnedColumnHeaders': {
				background: theme.palette.primary.main,
				color: theme.palette.secondary.main,
				'& .MuiSvgIcon-root': {
					fill: theme.palette.primary.dark,
				},
			},
		},
		'& .successIcon': {
			'& .MuiSvgIcon-root': {
				fill: theme.palette.success.main,
			},
		},
		'& .warningIcon': {
			'& .MuiSvgIcon-root': {
				fill: theme.palette.warning.main,
			},
		},
		'& .failedIcon': {
			'& .MuiSvgIcon-root': {
				fill: theme.palette.error.main,
			},
		},
		'& .listControlsExams': {
			display: 'inline',
			float: 'right',
			position: 'relative',
		},
		'& .cellAlignCenter': {
			width: '100%',
			textAlign: 'center',
		},
		'& .MuiDataGrid-cell': {
			cursor: 'pointer',
		},
		'& .cursorAuto': {
			cursor: 'auto',
		},
		'& .pagination': {
			display: 'flex',
			alignItems: 'center',
			float: 'right',
			position: 'absolute',
			right: '30px',
			bottom: '15px',
			'& .total-count': { marginRight: '20px' },
		},
		'& .gridBounding': {
			'& .MuiDataGrid-cell': {
				cursor: 'auto',
			},
			'& .MuiDataGrid-root .MuiDataGrid-row:hover': {
				backgroundColor: 'transparent',
				cursor: 'auto',
			},
		},
		'& .rightFloatAction': {
			margin: '10px 10px 10px 0',
			marginLeft: 'auto',
		},
	},
}));

export const getJobStatusIcon = (jobStatus, intl, tooltip) => {
	switch (jobStatus) {
		case JOB_STATUS.COMPLETED:
			return (
				<UniButtton
					icon={<CheckCircle size="small" />}
					className="successIcon"
					tooltip={tooltip || `${intl.formatMessage(messages.processingStatus)}: ${jobStatus}`}
					disabled
				/>
			);
		case JOB_STATUS.QUEUED:
			return (
				<UniButtton
					icon={<HourglassEmpty size="small" />}
					className="warningIcon"
					tooltip={tooltip || `${intl.formatMessage(messages.processingStatus)}: ${jobStatus}`}
					disabled
				/>
			);
		case JOB_STATUS.FAILED:
			return (
				<UniButtton
					icon={<Error size="small" />}
					className="failedIcon"
					tooltip={tooltip || `${intl.formatMessage(messages.processingStatus)}: ${jobStatus}`}
					disabled
				/>
			);
		default:
			return (
				<UniButtton
					icon={<Help size="small" />}
					tooltip={tooltip || `${intl.formatMessage(messages.processingStatus)}: ${jobStatus}`}
					disabled
				/>
			);
	}
};

export const getCommissionerReviewIcon = (commissionerReview, isFinal, finalViolationMessage) => {
	if (commissionerReview === 'FAIL')
		return (
			<UniButtton
				tooltip={
					<>
						<span>
							<FormattedMessage {...messages[isFinal ? 'finalReview' : 'commissioner']} />: FAIL
						</span>
						{isFinal && finalViolationMessage ? <span> ({finalViolationMessage})</span> : ''}
					</>
				}
				disabled
				className="failedIcon"
				icon={<ThumbDown />}
			/>
		);
	if (commissionerReview === 'PASS')
		return (
			<UniButtton
				tooltip={
					<span>
						<FormattedMessage {...messages[isFinal ? 'finalReview' : 'commissioner']} />: PASS
					</span>
				}
				disabled
				className="successIcon"
				icon={<ThumbUp />}
			/>
		);
	return (
		<UniButtton
			tooltip={
				<span>
					<FormattedMessage {...messages[isFinal ? 'finalReview' : 'commissioner']} />: -
				</span>
			}
			disabled
			className="warningIcon"
			icon={<Help />}
		/>
	);
};

export const getReviewerReviewIcon = (reviewerReview) => {
	if (reviewerReview === 'SUSPICIOUS')
		return (
			<UniButtton
				tooltip={
					<span>
						<FormattedMessage {...messages.reviewer} />: SUSPICIOUS
					</span>
				}
				disabled
				className="warningIcon"
				icon={<ThumbDown />}
			/>
		);
	if (reviewerReview === 'PASS')
		return (
			<UniButtton
				tooltip={
					<span>
						<FormattedMessage {...messages.reviewer} />: PASS
					</span>
				}
				disabled
				className="successIcon"
				icon={<ThumbUp />}
			/>
		);
	return (
		<UniButtton
			tooltip={
				<span>
					<FormattedMessage {...messages.reviewer} />: -
				</span>
			}
			disabled
			className="warningIcon"
			icon={<Help />}
		/>
	);
};

export const getStatusIcon = (status, intl) => {
	if (status === EXAM.FINISHED)
		return (
			<UniButtton
				tooltip={`${intl.formatMessage(messages.status)}: ${status}`}
				disabled
				className="successIcon"
				icon={<CheckCircle />}
			/>
		);
	if (status === EXAM.RUNNING)
		return (
			<UniButtton
				tooltip={`${intl.formatMessage(messages.status)}: ${status}`}
				disabled
				className="warningIcon"
				icon={<HourglassEmpty />}
			/>
		);
};

const ExamsList = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const gridRef = useGridApiRef();
	const isfetchExamsPending = useSelector(isPending(MODEL_ACTION_TYPES.FETCH_ID_URL));
	const exams = useSelector(getCurrentExams());
	const xgridConfig = useSelector(getXgridConfig());
	const config = xgridConfig[ENTITIES.EXAMS];
	const userRole = useSelector(getUserRole());
	const [assignedReviews, setAssignedReviews] = useState([]);
	const [filteredReviews, setFilteredReviews] = useState([]);
	const [postponedReviews, setPostponedReviews] = useState([]);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const isSysadminOrSuperadmin = userRole === USER.ROLES.SYSADMIN || userRole === USER.ROLES.SUPERADMIN;
	const isSuperadmin = userRole === USER.ROLES.SUPERADMIN;
	const definition = useSelector(getCurrentDefinition);
	const users = Object.values(useSelector(getUsers) || {});
	const emails = users.map((user) => user.email);
	const rows = useMemo(() => prepareExams(intl, exams).filter(({ id }) => !!id), [exams, intl]);

	const columns = useMemo(
		() => [
			{
				field: 'detail',
				headerName: '',
				width: 55,
				resizable: false,
				filterable: false,
				sortable: false,
				pinnable: false,
				disableExport: true,
				renderCell: (params) => (
					<Link
						to={`${ROUTES.EXAMS.PATH}/${params?.row?.definitionId}/${params?.row?.id}`}
						onContextMenu={(e) =>
							redirectInsteadMenu(e, `${ROUTES.EXAMS.PATH}/${params?.row?.definitionId}/${params?.row?.id}`)
						}
					>
						<UniButtton type="icon" icon={<FileOpen />} tooltip={<FormattedMessage {...messages.detail} />} />
					</Link>
				),
			},
			{
				field: 'firstName',
				headerName: intl.formatMessage(messages.firstName),
				width: 180,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'lastName',
				headerName: intl.formatMessage(messages.lastName),
				width: 180,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'dateOfBirth',
				headerName: intl.formatMessage(messages.birthDate),
				width: 180,
				valueFormatter: ({ value }) => toDateCzLocalized(value),
				type: 'date',
			},
			{
				field: 'status',
				headerName: intl.formatMessage(messages.status),
				width: 180,
				renderCell: (params) => <div>{getStatusIcon(params.row.status, intl)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(Object.values(EXAM), intl, false),
			},
			{
				field: 'jobStatus',
				headerName: intl.formatMessage(messages.processingStatus),
				width: 180,
				renderCell: (params) => (
					<div>
						{params.row.jobStatus !== JOB_STATUS.FAILED
							? getJobStatusIcon(params.row.jobStatus, intl)
							: getJobStatusIcon(
									params.row.jobStatus,
									intl,
									`${intl.formatMessage(messages.processingStatus)}: ${params.row.jobStatus} - ${
										params.row.jobFailedReason
									}`
							  )}
					</div>
				),
				type: 'singleSelect',
				filterOperators: selectOperators(Object.values(JOB_STATUS), intl, false),
			},
			{
				field: 'finalReview',
				headerName: intl.formatMessage(messages.finalReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.finalReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
			{
				field: 'finalViolationMessage',
				headerName: intl.formatMessage(messages.finalViolationMessage),
				width: 170,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'violationNote',
				headerName: intl.formatMessage(messages.violationNote),
				width: 230,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'evaluationDetail',
				headerName: intl.formatMessage(messages.evaluationDetail),
				width: 230,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'finalViolationCode',
				headerName: intl.formatMessage(messages.finalViolationCode),
				width: 150,
				align: 'right',
				type: 'number',
				headerAlign: 'right',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'isViolationIntentional',
				headerName: intl.formatMessage(messages.intentionalViolation),
				width: 210,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'commissionerReview',
				headerName: intl.formatMessage(messages.commissionerReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.commissionerReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
			{
				field: 'commissionerReviewedBy',
				headerName: intl.formatMessage(messages.commissionerReviewedBy),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
				valueOptions: [],
			},
			{
				field: 'commissionerReviewedAt',
				headerName: intl.formatMessage(messages.commissionerReviewedAt),
				width: 280,
				renderCell: (params) => <div>{formatDate(params.row.commissionerReviewedAt)}</div>,
				type: 'dateTime',
				valueGetter: (params) => new Date(params.row.commissionerReviewedAt),
			},
			{
				field: 'commissionerReviewDurationSec',
				headerName: intl.formatMessage(messages.commissionerReviewDurationSec),
				filterOperators: timeLengthOperators('ss'),
				renderCell: (params) => <div>{msToTime(params.row.commissionerReviewDurationSec * 1000)}</div>,
				type: 'singleSelect',
				width: 220,
			},
			{
				field: 'reviewerReview',
				headerName: intl.formatMessage(messages.reviewerReview),
				width: 230,
				renderCell: (params) => <div>{getReviewerReviewIcon(params.row.reviewerReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(REVIEWER_REVIEW, intl, false),
			},
			{
				field: 'reviewerReviewedBy',
				headerName: intl.formatMessage(messages.reviewerReviewedBy),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
				valueOptions: [],
			},
			{
				field: 'reviewerReviewedAt',
				headerName: intl.formatMessage(messages.reviewerReviewedAt),
				width: 280,
				renderCell: (params) => <div>{formatDate(params.row.reviewerReviewedAt)}</div>,
				type: 'dateTime',
				valueGetter: (params) => new Date(params.row.reviewerReviewedAt),
			},
			{
				field: 'reviewerReviewDurationSec',
				headerName: intl.formatMessage(messages.reviewerReviewDurationSec),
				filterOperators: timeLengthOperators('ss'),
				renderCell: (params) => <div>{msToTime(params.row.reviewerReviewDurationSec * 1000)}</div>,
				type: 'singleSelect',
				width: 220,
			},
			{
				field: 'customViolationsCount',
				headerName: intl.formatMessage(messages.customViolationsCount),
				width: 200,
				align: 'right',
				type: 'number',
				headerAlign: 'right',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'aiViolationsCount',
				headerName: intl.formatMessage(messages.aiViolationsCount),
				width: 200,
				align: 'right',
				type: 'number',
				headerAlign: 'right',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'startedAt',
				headerName: intl.formatMessage(messages.startedAt),
				width: 180,
				valueFormatter: ({ value }) => formatDate(value),
				type: 'dateTime',
				valueGetter: (params) => new Date(params.row.startedAt),
			},
			{
				field: 'endedAt',
				headerName: intl.formatMessage(messages.endedAt),
				width: 180,
				valueFormatter: ({ value }) => formatDate(value),
				type: 'dateTime',
				valueGetter: (params) => new Date(params.row.endedAt),
			},
			{
				field: 'durationFilterable',
				filterOperators: timeLengthOperators('ms'),
				headerName: intl.formatMessage(messages.duration),
				renderCell: (params) => <div>{msToTime(params.row.durationFilterable)}</div>,
				type: 'singleSelect',
				width: 120,
			},
			{
				field: 'postponed',
				headerName: intl.formatMessage(messages.postponedReview),
				width: 200,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'assignedTo',
				headerName: intl.formatMessage(messages.assignedTo),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
				valueOptions: [],
			},
			{
				field: 'userRole',
				headerName: intl.formatMessage(messages.userRoleAssignedTo),
				width: 240,
				type: 'singleSelect',
				valueOptions: [
					intl.formatMessage(messages.reviewer),
					intl.formatMessage(messages.commissioner),
					intl.formatMessage(messages.superadmin),
				],
			},
			{
				field: 'consistency',
				headerName: intl.formatMessage(messages.consistency),
				width: 160,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'crossTermConsistency',
				headerName: intl.formatMessage(messages.crossTermConsistency),
				width: 260,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'id',
				headerName: intl.formatMessage(messages.examId),
				disableClickEventBubbling: true,
				width: 320,
				cellClassName: 'cursorAuto',
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'customerId',
				headerName: intl.formatMessage(messages.customerId),
				width: 320,
				renderCell: (params) => <div>{params.row.customerId}</div>,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'externalId',
				headerName: intl.formatMessage(messages.externalId),
				disableClickEventBubbling: true,
				width: 310,
				cellClassName: 'cursorAuto',
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
		],
		[emails, intl]
	);

	// XGrid configuration
	const dataGridConfig = useMemo(
		() => ({
			checkboxSelection: isSysadminOrSuperadmin,
			disableRowSelectionOnClick: true,
			gridId: ENTITIES.EXAMS,
			components: { Toolbar: () => <XGridCustomToolbar fileName={`${ENTITIES.EXAMS}_${definition?.name}`} /> },
			rows: isfetchExamsPending ? [] : rows,
			// remember filtering
			onFilterModelChange: (e, { api }) => {
				if (isSysadminOrSuperadmin) {
					let visibleRows = [];

					const rows = gridExpandedSortedRowEntriesSelector(gridRef);

					// weird workaround - on prod build e.visibleRows is a Map, on localhost it's an array
					if (rows instanceof Map) {
						for (const [, value] of rows) {
							visibleRows.push(value);
						}
					} else {
						visibleRows = rows;
					}

					if (isEmpty(e.items.filter((item) => item.value))) {
						// remove assigned & postponed exams
						setAssignedReviews([]);
						setFilteredReviews([]);
						setPostponedReviews([]);
					} else {
						// collect assigned & postponed exams
						const visibleRowsIds = visibleRows.map((exam) => exam.id);

						setAssignedReviews(visibleRows.filter((exam) => exam.assignedTo).map((exam) => exam.id));
						setFilteredReviews(visibleRowsIds);
						setRowSelectionModel(visibleRowsIds);
						setPostponedReviews(visibleRows.filter((exam) => exam.postponed !== '-').map((exam) => exam.id));
					}

					gridRef.current.setRowSelectionModel([]);
				}

				// store filter model config
				dispatch(setXGridConfig({ ...config, filterModel: e }, ENTITIES.EXAMS));
			},
			onRowSelectionModelChange: (rows) => {
				setFilteredReviews(rows);
				setRowSelectionModel(rows);
				setPostponedReviews(
					rows
						.map((id) => exams.find((exam) => exam.id === id))
						.filter((exam) => exam.postponed !== '-')
						.map((exam) => exam.id)
				);
				setAssignedReviews(
					rows
						.map((id) => exams.find((exam) => exam.id === id))
						.filter((exam) => exam.assignedTo)
						.map((exam) => exam.id)
				);
			},
			rowSelectionModel,
			columns,
			apiRef: gridRef,
		}),
		[
			columns,
			config,
			definition?.name,
			dispatch,
			exams,
			gridRef,
			isSysadminOrSuperadmin,
			isfetchExamsPending,
			rowSelectionModel,
			rows,
		]
	);

	const cleanupSelection = () => {
		setAssignedReviews([]);
		setFilteredReviews([]);
		setPostponedReviews([]);
		setRowSelectionModel([]);
	};

	const handleResetPostponedReviews = async () => {
		await dispatch(resetPostponedReviews(postponedReviews, intl));
		cleanupSelection();
	};

	const handleAssignReviews = async (examIds, userId) => {
		await dispatch(assignReviews(examIds, userId, intl));
		cleanupSelection();
	};

	const handleResetAssignedReviews = async () => {
		await dispatch(resetAssignedReviews(assignedReviews, intl));
		cleanupSelection();
	};

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	// Save scroll coordinates
	const saveScrollCoordinates = useCallback(
		() => localStorage.setItem('examsScrollCoordinates', JSON.stringify(gridRef.current.getScrollPosition())),
		[gridRef]
	);

	// Apply last scroll coordinates
	useEffect(() => {
		const initScrollPos = localStorage.getItem('examsScrollCoordinates');

		if (gridRef?.current?.scroll && initScrollPos)
			setTimeout(() => gridRef.current?.scroll(JSON.parse(initScrollPos)), 1);
	}, [gridRef]);

	// Listen to scroll
	useEffect(() => {
		setTimeout(() => {
			document.querySelector('.MuiDataGrid-virtualScroller')?.addEventListener('scroll', () => {
				_debouce(saveScrollCoordinates);
			});
		}, 1);
	}, [saveScrollCoordinates]);

	return (
		<div className={classes.bounding}>
			<Breadcrumbs />

			<UniButtton
				className="listControlsExams"
				onClick={() => dispatch(fetchExams(definition.id))}
				icon={<Refresh size="small" />}
				tooltip={<FormattedMessage {...messages.refreshExams} />}
			/>

			{!isEmpty(filteredReviews) && isSysadminOrSuperadmin && (
				<>
					<UniButtton
						className="listControlsExams"
						onClick={() => dispatch(showAssignReviewModal(filteredReviews, handleAssignReviews))}
						icon={<PersonAdd size="small" />}
						tooltip={<FormattedMessage {...messages.assignReviews} />}
					/>
				</>
			)}
			{!isEmpty(filteredReviews) && isSuperadmin && (
				<UniButtton
					className="listControlsExams"
					onClick={() => dispatch(showRemoveExamsModal(filteredReviews, cleanupSelection))}
					icon={<Delete size="small" />}
					tooltip={<FormattedMessage {...messages.removeExams} />}
				/>
			)}
			{!isEmpty(postponedReviews) && isSysadminOrSuperadmin && (
				<UniButtton
					className="listControlsExams"
					onClick={handleResetPostponedReviews}
					icon={<ReplyAll size="small" />}
					tooltip={<FormattedMessage {...messages.resetPostponedReviews} />}
				/>
			)}
			{!isEmpty(assignedReviews) && isSysadminOrSuperadmin && (
				<UniButtton
					className="listControlsExams"
					onClick={handleResetAssignedReviews}
					icon={<RotateLeft size="small" />}
					tooltip={<FormattedMessage {...messages.resetAssignedReviews} />}
				/>
			)}
			{!isEmpty(filteredReviews) && isSuperadmin && (
				<>
					<UniButtton
						className="listControlsExams"
						onClick={() =>
							dispatch(
								showConfirmModal({
									handler: () => {
										dispatch(restartProcessing(filteredReviews, intl));
										dispatch(hideModal());
									},
									text: <FormattedMessage {...messages.reprocessExamsAgainConfirm} />,
									title: <FormattedMessage {...messages.reprocessExamsAgain} />,
									button: <FormattedMessage {...messages.reprocessExams} />,
								})
							)
						}
						icon={<SyncProblem size="small" />}
						tooltip={<FormattedMessage {...messages.processExams} />}
					/>
				</>
			)}
			<br />
			<Paper elevation={3}>
				<div style={{ height: 'calc(100vh - 130px)' }}>
					<DataGrid {...dataGridConfig} unstable_headerFilters className="withHeaderFilters" />
				</div>
			</Paper>
		</div>
	);
};

export default ExamsList;
