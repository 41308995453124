import {
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-pro';

const XGridCustomToolbar = ({ children, fileName, disableExport }) => {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			{!disableExport && (
				<GridToolbarExport
					csvOptions={{ fileName, delimiter: ';', utf8WithBom: true }}
					printOptions={{ disableToolbarButton: true }}
				/>
			)}
			{children}
		</GridToolbarContainer>
	);
};

export default XGridCustomToolbar;
